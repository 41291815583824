/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useContext, useMemo } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { API_URL, STATIC_URL, custom_axios } from "../Common/Common";
import { useHistory } from "react-router-dom";
import { Store } from "../Store/store";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import Search from "@mui/icons-material/Search";
import ShowImageInfo from "../Common/ShowImageInfo";
import ShowVideoInfo from "../Common/ShowVideoInfo";
import ShowBotImageInfo from "../Common/ShowBotImageInfo";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { MultiSelect } from "react-multi-select-component";
import Skeleton from "@mui/material/Skeleton";
import Slide from "@mui/material/Slide";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tooltip from "@mui/material/Tooltip";
// import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

import { FaRegFilePowerpoint, FaRegFileExcel, FaRegFilePdf, FaRegFileWord, FaRegFileAlt } from "react-icons/fa";
import styled from "@emotion/styled";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const headCells = [
  { id: "time", label: "時刻" },
  { id: "kbn", label: "種類" },
  { id: "filename", label: "ファイル名" },
  { id: "size", label: "サイズ" },

  { id: "name", label: "名前" },
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <StyledSpan>{order === "desc" ? "sorted descending" : "sorted ascending"}</StyledSpan>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function LoadingTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" padding="normal">
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const dateFormat = {
  _fmt: {
    yyyy: function (date) {
      return date.getFullYear() + "";
    },
    MM: function (date) {
      return ("0" + (date.getMonth() + 1)).slice(-2);
    },
    dd: function (date) {
      return ("0" + date.getDate()).slice(-2);
    },
    hh: function (date) {
      return ("0" + date.getHours()).slice(-2);
    },
    mm: function (date) {
      return ("0" + date.getMinutes()).slice(-2);
    },
    ss: function (date) {
      return ("0" + date.getSeconds()).slice(-2);
    },
  },
  _priority: ["yyyy", "MM", "dd", "hh", "mm", "ss"],
  format: function (date, format) {
    return this._priority.reduce((res, fmt) => res.replace(fmt, this._fmt[fmt](date)), format);
  },
};

const StyledGridListroot = styled("div")`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.background.paper};
`;

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
const StyledSaveButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDiv = styled("div")`
  width: 100%;
`;
const StyledPaper = styled(Paper)`
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing(2) + "px"};
`;
const StyledTable = styled(Table)`
  min-width: 750;
`;
const StyledSpan = styled("span")`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1;
`;
const StyledCard = styled(Card)`
  width: 200;
  height: 200;
  margin: ${(props) => props.theme.spacing(2) + "px"};
`;
const StyledSkeleton = styled(Skeleton)`
  height: 100;
`;

function ShowPhotos({ open, handleClose }) {
  const history = useHistory();
  const { state } = useContext(Store);

  const [filesList, setFilesList] = useState([]);
  const [filesListorg, setFilesListOrg] = useState([]);

  const [useroptions, setUserOptions] = useState([]);
  const [kbnoptions] = useState([
    { label: "写真", value: "photo" },
    { label: "ビデオ", value: "video" },
    { label: "ファイル", value: "file" },
  ]);
  // let isbotroom = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].isbotroom;
  // const getLastYear = () => {
  //   var d = new Date();

  //   if (isbotroom == "1") {
  //     d.setDate(d.getDate() - 1);
  //   } else {
  //     d.setYear(d.getFullYear() - 1);
  //   }
  //   return d;
  // };

  const [selectedstart, setSelectedStart] = useState("");
  const [selectedend, setSelectedEnd] = useState(new Date());
  const [selecteduser, setSelectedUser] = useState([{ label: state.user_name, value: state.honbuid }]);
  const [selectedkbn, setSelectedKbn] = useState([
    { label: "写真", value: "photo" },
    { label: "ビデオ", value: "video" },
    { label: "ファイル", value: "file" },
  ]);

  const [sword, setSword] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const proc = async () => {
    setLoading(true);
    let joinkbns = selectedkbn
      .map((e) => {
        return e.value;
      })
      .join("_");

    let joinusers = selecteduser
      .map((e) => {
        return e.value;
      })
      .join("_");
    //("TEST", joinkbns, joinusers);
    if (joinkbns.length == 0 || joinusers.length == 0) {
      setFilesList([]);
      setFilesListOrg([]);
      return;
    }
    let roomid = state.currentRoomid;

    const reqapi =
      API_URL +
      `/GetFilesAndPhotos?roomid=${roomid}&kbns=${joinkbns}&users=${joinusers}&start=${dateFormat.format(
        selectedstart,
        "yyyy/MM/dd"
      )}&end=${dateFormat.format(selectedend, "yyyy/MM/dd")}`;
    //console.log("TEST", reqapi);
    const result = await custom_axios(reqapi);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    setLoading(false);
    if (status === 200) {
      setFilesList(result.data.photos);
      setFilesListOrg(result.data.photos);
    } else {
      setFilesList([]);
      setFilesListOrg([]);
    }
  };

  useEffect(() => {
    if (sword != "") {
      const updateList = filesListorg.filter((item) => String(item.filename).indexOf(sword) !== -1);
      setFilesList(updateList);
    } else {
      setFilesList(filesListorg);
    }
  }, [sword]);

  useEffect(() => {
    if (open) {
      setUserOptions([
        {
          value: state.honbuid,
          label: state.user_name,
        },
        ...state.chatrooms
          .filter((f) => f.roomid === state.currentRoomid)[0]
          .memberlist.map((e) => {
            return { label: e.name, value: e.honbuid };
          }),
      ]);
      setSelectedUser([
        {
          value: state.honbuid,
          label: state.user_name,
        },
        ...state.chatrooms
          .filter((f) => f.roomid === state.currentRoomid)[0]
          .memberlist.map((e) => {
            return { label: e.name, value: e.honbuid };
          }),
      ]);

      let isbotroom = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].isbotroom;

      var d = new Date();

      if (isbotroom == "1") {
        d.setDate(d.getDate() - 1);
      } else {
        d.setYear(d.getFullYear() - 1);
      }
      setSelectedStart(d);

      setInitialized(true);
    }
  }, [open]);
  useEffect(() => {
    //console.log("ログ中 state", state);
    if (open && initialized) {
      proc();
    }
  }, [open, selectedkbn, selecteduser, selectedend, selectedstart, initialized]);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [view, setView] = React.useState("list");

  const handleChange = (event, nextView) => {
    setView(nextView);
  };

  const [imgopen, setImgOpen] = React.useState(false);
  const [botimgopen, setBotImgOpen] = React.useState(false);
  const [boturl, setBotUrl] = React.useState("");
  const [videoopen, setVideoOpen] = React.useState(false);
  const [initMessageid, setInitMessageid] = React.useState([]);

  const MemoImage = useMemo(() => {
    return <ShowImageInfo open={imgopen} messageid={initMessageid} handleClose={() => setImgOpen(false)} />;
  }, [imgopen, initMessageid]);
  const MemoBotImage = useMemo(() => {
    return <ShowBotImageInfo open={botimgopen} url={boturl} handleClose={() => setBotImgOpen(false)} />;
  }, [botimgopen, boturl]);
  const MemoVideo = useMemo(() => {
    return <ShowVideoInfo open={videoopen} messageid={initMessageid} handleClose={() => setVideoOpen(false)} />;
  }, [videoopen, initMessageid]);

  const getSafix = (a) => {
    return a.split(".").pop();
  };

  return (
    // <MuiThemeProvider theme={theme}>
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">ファイル/写真一覧</div>
          <div className="question-title-actions">
            <div className="multi-select">
              <div className="show-chat-search">
                <div className="show-chat-search-icon">
                  <Search />
                </div>
                <input
                  placeholder="ファイル名 検索"
                  //value={chatsFilter}
                  name="chatsFilter"
                  onChange={(e) => {
                    setSword(e.target.value);
                  }}
                  className="file-search-field"
                />
              </div>
            </div>
            <StyledSaveButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledSaveButton>
          </div>
        </div>
      </StyledDialogTitle>
      <StyledDialogContent>
        <StyledDiv>
          <div className="user-select-area">
            <div className="user-select-subarea">
              <div className="date-select">期間:</div>
              <div className="date-select">
                <LocalizationProvider adapterLocale={ja} dateAdapter={AdapterDateFns} dateFormats={{ year: "yyyy年" }}>
                  <DatePicker
                    okLabel="決定"
                    cancelLabel="キャンセル"
                    id="date-picker-dialog"
                    format="yyyy/MM/dd"
                    value={selectedstart}
                    onChange={(e) => {
                      setSelectedStart(e);
                    }}
                    slotProps={{ textField: { size: "small" }, calendarHeader: { format: "yyyy/MM" } }}
                  />
                </LocalizationProvider>
              </div>
              <div className="date-select">～</div>
              <div className="date-select">
                <LocalizationProvider adapterLocale={ja} dateAdapter={AdapterDateFns} dateFormats={{ year: "yyyy年" }}>
                  <DatePicker
                    okLabel="決定"
                    cancelLabel="キャンセル"
                    id="date-picker-dialog"
                    format="yyyy/MM/dd"
                    value={selectedend}
                    onChange={(e) => {
                      setSelectedEnd(e);
                    }}
                    slotProps={{ textField: { size: "small" }, calendarHeader: { format: "yyyy/MM" } }}
                  />
                </LocalizationProvider>
              </div>

              <div className="multi-select">
                <MultiSelect
                  options={kbnoptions}
                  value={selectedkbn}
                  onChange={(e) => {
                    setSelectedKbn(e);
                  }}
                  labelledBy={"Select"}
                  overrideStrings={{
                    selectSomeItems: "選択...",
                    allItemsAreSelected: "全選択",
                    selectAll: "全選択",
                    search: "検索",
                    clearSearch: "検索クリア",
                  }}
                  filterOptions={(options, filter) => {
                    if (!filter) {
                      return options;
                    }
                    const re = new RegExp(filter, "i");
                    return options.filter(({ label }) => label && label.match(re));
                  }}
                />
              </div>
              <div className="multi-select">
                <MultiSelect
                  options={useroptions}
                  value={selecteduser}
                  onChange={(e) => {
                    setSelectedUser(e);
                  }}
                  labelledBy={"Select"}
                  overrideStrings={{
                    selectSomeItems: "選択...",
                    allItemsAreSelected: "全選択",
                    selectAll: "全選択",
                    search: "検索",
                    clearSearch: "検索クリア",
                  }}
                  filterOptions={(options, filter) => {
                    if (!filter) {
                      return options;
                    }
                    const re = new RegExp(filter, "i");
                    return options.filter(({ label }) => label && label.match(re));
                  }}
                />
              </div>
            </div>
            <div className="user-toggle-subarea">
              <ToggleButtonGroup value={view} exclusive onChange={handleChange}>
                <ToggleButton value="list" aria-label="list">
                  <ViewListIcon />
                </ToggleButton>
                <ToggleButton value="module" aria-label="module">
                  <ViewModuleIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          {view == "list" ? (
            loading ? (
              <div>
                <StyledPaper>
                  <TableContainer>
                    <StyledTable
                      //className={classes.table}
                      aria-labelledby="tableTitle"
                      //size={dense ? "small" : "medium"}
                      size="small"
                      aria-label="enhanced table"
                    >
                      <LoadingTableHead />
                      <TableBody>
                        {[...Array(15)].map((_, i) => {
                          const skeletonWidth = 60;
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                              {/* <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
                        </TableCell> */}
                              <TableCell component="th">
                                <Skeleton animation="wave" variant="rect" width={skeletonWidth} />
                              </TableCell>
                              <TableCell align="left">
                                <Skeleton animation="wave" variant="rect" width={skeletonWidth} />
                              </TableCell>
                              <TableCell align="left">
                                <Skeleton animation="wave" variant="rect" width={skeletonWidth} />
                              </TableCell>
                              <TableCell align="left">
                                <Skeleton animation="wave" variant="rect" width={skeletonWidth} />
                              </TableCell>
                              <TableCell align="left">
                                <Skeleton animation="wave" variant="rect" width={skeletonWidth} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </StyledTable>
                  </TableContainer>
                </StyledPaper>
              </div>
            ) : (
              <StyledPaper>
                <TableContainer>
                  <StyledTable
                    aria-labelledby="tableTitle"
                    //size={dense ? "small" : "medium"}
                    size="small"
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      //classes={classes}
                      //numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      //onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      //rowCount={membersList.length}
                    />
                    <TableBody>
                      {stableSort(filesList, getComparator(order, orderBy)).map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            //onClick={(event) => handleClick(event, row.honbuid)}
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            {/* <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
                        </TableCell> */}
                            <TableCell align="left">{row.time}</TableCell>
                            <TableCell align="left">
                              {row.kbn == "photo"
                                ? "写真"
                                : row.kbn == "botphoto"
                                  ? "写真"
                                  : row.kbn == "video"
                                    ? "ビデオ"
                                    : "ファイル"}
                            </TableCell>
                            <TableCell align="left">
                              {row.kbn == "file" ? (
                                <a href={STATIC_URL + `/downloadfile?messageID=${row.messageid}`}>{row.filename}</a>
                              ) : row.kbn == "photo" ? (
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setInitMessageid(row.messageid);
                                    setImgOpen(true);
                                  }}
                                >
                                  写真
                                </a>
                              ) : row.kbn == "botphoto" ? (
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setBotUrl(row.filenme);
                                    setBotImgOpen(true);
                                  }}
                                >
                                  写真
                                </a>
                              ) : (
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setInitMessageid(row.messageid);
                                    setVideoOpen(true);
                                  }}
                                >
                                  動画
                                </a>
                              )}
                            </TableCell>
                            <TableCell align="left">{row.size}</TableCell>

                            <TableCell component="th" id={labelId}>
                              <div className="member-content">
                                <img
                                  src={
                                    STATIC_URL +
                                    `/downloadAvatar?honbuid=${row["honbuid"]}&fileno=${row["fileno"]}&size=small`
                                  }
                                  width="30"
                                  height="30"
                                  alt=""
                                  className="member-image"
                                  //style={isCurrent ? { border: "1px solid white" } : null}
                                />
                                {row.name}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </StyledTable>
                </TableContainer>
              </StyledPaper>
            )
          ) : loading ? (
            <StyledGridListroot>
              {[...Array(15)].map((_, i) => {
                return (
                  <div key={i}>
                    <StyledCard>
                      <StyledSkeleton animation="wave" variant="rect" />

                      <CardContent>
                        <React.Fragment>
                          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                          <Skeleton animation="wave" height={10} width="80%" />
                        </React.Fragment>
                      </CardContent>
                    </StyledCard>
                  </div>
                );
              })}
            </StyledGridListroot>
          ) : (
            <StyledGridListroot>
              {filesList.map((tile, index) => (
                <div className="tile-box" key={index}>
                  {tile.kbn == "photo" ? (
                    <div>
                      <Tooltip
                        title={
                          <>
                            <div>{tile.name}</div>
                            <div>{tile.size}</div>
                            <div>{tile.time}</div>
                          </>
                        }
                      >
                        <img
                          src={STATIC_URL + `/downloadImage?messageID=${tile.messageid}&size=min`}
                          alt=""
                          className="show-tile-image"
                          onClick={() => {
                            setInitMessageid(tile.messageid);
                            setImgOpen(true);
                          }}
                          //style={isCurrent ? { border: "1px solid white" } : null}
                        />
                      </Tooltip>
                      <div className="show-tile-information">写真</div>
                    </div>
                  ) : tile.kbn == "botphoto" ? (
                    <div>
                      <Tooltip
                        title={
                          <>
                            <div>{tile.name}</div>
                            <div>{tile.size}</div>
                            <div>{tile.time}</div>
                          </>
                        }
                      >
                        <img
                          src={tile.filename}
                          alt=""
                          className="show-tile-image"
                          onClick={() => {
                            setBotUrl(tile.filename);
                            setBotImgOpen(true);
                          }}
                          //style={isCurrent ? { border: "1px solid white" } : null}
                        />
                      </Tooltip>
                      <div className="show-tile-information">写真</div>
                    </div>
                  ) : tile.kbn == "video" ? (
                    <div>
                      <div className="chat-message-video-wrap">
                        <Tooltip
                          title={
                            <>
                              <div>{tile.name}</div>
                              <div>{tile.size}</div>
                              <div>{tile.time}</div>
                            </>
                          }
                        >
                          <img
                            src={STATIC_URL + `/downloadVideo?messageID=${tile.messageid}&size=min`}
                            alt=""
                            className="show-tile-image"
                            onClick={() => {
                              setInitMessageid(tile.messageid);
                              setVideoOpen(true);
                            }}
                            //style={isCurrent ? { border: "1px solid white" } : null}
                          />
                        </Tooltip>
                        <div
                          className="chat-message-video-icon"
                          onClick={() => {
                            setInitMessageid(tile.messageid);
                            setVideoOpen(true);
                          }}
                        >
                          <PlayCircleOutlineIcon fontSize="large" style={{ color: "white" }} />
                        </div>
                      </div>
                      <div className="show-tile-information">動画</div>
                    </div>
                  ) : (
                    <div>
                      <Tooltip
                        title={
                          <>
                            <div>{tile.filename}</div>
                            <div>{tile.name}</div>
                            <div>{tile.size}</div>
                            <div>{tile.time}</div>
                          </>
                        }
                      >
                        <div className="show-tile-file">
                          <a
                            //className="show-tile-file"
                            href={STATIC_URL + `/downloadfile?messageID=${tile.messageid}`}
                          >
                            {["pptx", "ppt"].includes(getSafix(tile.filename)) ? (
                              <FaRegFilePowerpoint style={{ height: "100px", width: "100px", color: "#C43E1C" }} />
                            ) : ["pdf"].includes(getSafix(tile.filename)) ? (
                              <FaRegFilePdf style={{ height: "100px", width: "100px", color: "#A4373A" }} />
                            ) : ["docx", "doc"].includes(getSafix(tile.filename)) ? (
                              <FaRegFileWord style={{ height: "100px", width: "100px", color: "#185ABD" }} />
                            ) : ["xlsx", "xls"].includes(getSafix(tile.filename)) ? (
                              <FaRegFileExcel style={{ height: "100px", width: "100px", color: "#107C41" }} />
                            ) : (
                              <FaRegFileAlt style={{ height: "100px", width: "100px", color: "#000000" }} />
                            )}
                          </a>
                        </div>
                      </Tooltip>
                      <div className="show-tile-information">{tile.filename}</div>
                    </div>
                  )}
                </div>
              ))}
            </StyledGridListroot>
          )}

          {MemoImage}
          {MemoBotImage}
          {MemoVideo}
        </StyledDiv>
      </StyledDialogContent>
    </Dialog>
    // </MuiThemeProvider>
  );
}

export default ShowPhotos;
